.secondary-section {
  padding: 2rem;
  padding-left: 8rem;
  margin: auto;
}
.section-inner {
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 25px;
  text-align: center;
}

.contact {
  text-align: center;
}

.contact_form {
  -moz-box-shadow: inset -3px -3px 10px #eee;
  -webkit-box-shadow: inset -3px -3px 10px #eee;
  box-shadow: inset -3px -3px 10px #eee;
  border-radius: 5px;
  padding-top: 1rem;
  margin-top: 2rem;
  width: 35%;
  background-color: #2c6c6c;
  opacity: 0.8;
  display: inline-block;
}

.contact_form * {
  margin-bottom: 10px;
  padding-left: 1rem;
}

input[type='text'],
select,
textarea {
  width: 55%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: none;
}

textarea {
  width: 90%;
  height: 10rem;
}

.section-inner__contact {
  margin-top: 3rem;
}

input[type='submit'] {
  border-radius: 10px;
  width: 130px;
  height: 50px;
  font-weight: bold;
  font-size: 1.5rem;
  background-color: #d1c3b1;
  padding-right: 1rem;
}
@media screen and (max-width: 1100px) {
  .contact_form {
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 85%;
  }
}
