.project-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.section-inner {
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 25px;
}
.title-box {
  display: inline-block;
  padding-top: 3rem;
}
.projects-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 12rem 3rem;
  grid-column-gap: 150px;
  justify-content: center;
}
.projects-animated:hover {
  cursor: pointer;
}
.projects-animated {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 400px;
  width: 100%;
  display: flex;
  row-gap: 15px;
}
.projects-animated h1 {
  color: #99752c;
  font-weight: bold;
  background-color: black;
  border-radius: 25px;
  padding: 20px;
  opacity: 0.8;
}
.projects-animated__box {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.text-wrapper {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  background: #fbfdffe1;
  font-size: 1;
}
.title-wrapper {
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
  width: 1;
  height: '100%';
}

.projects-title__parent {
  white-space: nowrap;
}
.projects-title__child {
  height: 1.5rem;
  float: bottom;
  color: #d1c3b1;
}
@media screen and (max-width: 800px) {
  .section-inner {
    max-width: 90%;
    line-height: 1.4;
    border-radius: 25px;
  }
  .skills .section-inner {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 1100px) {
  .projects-box {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 1rem;
  }

  .projects-animated {
    margin-bottom: 3rem;
  }
  .projects-title__parent h2 {
    text-align: center;
  }
}
