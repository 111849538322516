.about-section {
  padding-top: 3rem;
  align-content: center;
}
.home-section h3 {
  text-align: center;
}
.about-section-inner {
  font-family: 'Open Sans';
  width: 75%;
  padding-left: 2rem;
  margin-left: 10%;
  text-align: center;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
@media screen and (max-width: 800px) {
  .home-section {
    width: 100vw;
  }

  .home-section h2 {
  }

  .home-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .about-section-inner {
    margin-left: 5%;
  }
}

@media screen and (max-width: 1000px) {
}
