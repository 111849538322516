.secondary-section {
  padding: 2rem;
  padding-left: 8rem;
  margin: auto;
}
.section-inner {
  position: relative;
  padding: 2rem;
  background-color: #fff;
  border-radius: 25px;
}
.checkmark {
  color: rgba(23, 184, 23, 0.685);
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.skill-list {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.skills-contact {
  padding-top: 3rem;
}

.skill-item {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-top: 1rem;
  background-color: #575851;
  color: white;
  text-align: center;
  align-content: center;
  padding-bottom: 1.2rem;
  margin-top: 1.2rem;
  height: 80px;
}

.checkmark {
  padding-top: 1.2rem;
}

@media screen and (max-width: 1100px) {
  .skills .section-inner {
    padding: auto;
  }
  .secondary-section {
    padding-left: 1rem;
  }
  .secondary-section .contact {
    text-align: center;
  }
  .section-inner__skills {
    line-height: 1.4;
    border-radius: 25px;
  }

  .skill-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 10px;
  }

  .skill-item {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-top: 1rem;
    background-color: #575851;
    color: white;
    text-align: center;
    align-content: center;
    padding-bottom: 1.2rem;
    margin-top: 1.2rem;
    height: 80px;
  }
}
