.main-header {
  max-height: 30px;
  background-color: #000;
  color: #fff;
  position: fixed;
  z-index: 20;
  min-width: 100%;
  opacity: 0.8;
  padding: 1.5rem;
}

.main-header ul {
  list-style-type: none;
  margin: 0;
  float: left;
  display: inline-flex;
}

.header-nav {
  float: right;
  padding: 2.5vh 60px 10 0;
  margin-left: 2rem;
  font-size: medium;
}

.header-nav a {
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 50ms ease-in-out;
}

.header-nav a:hover,
.header-nav a:active {
  color: #d1c3b1;
}

.header-nav > li {
  display: inline-block;
  margin-left: 1em;
}

ul il {
  display: inline;
  float: left;
  margin-left: 10rem;
}

@media screen and (max-width: 1000px) {
  .main-header {
    margin-left: -3.4rem;
    width: 110vw;
  }
  .header-nav {
    margin-left: 1rem;
  }
}
