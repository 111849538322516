.full-hero {
  background: url('../../data/images/Hero_Background.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  box-sizing: border-box;
  overflow: hidden;
  background-position: top center;
  min-width: 100%;
  height: 1000px;
  background-size: cover;
}

/* .full-hero {
  background: url('../../data/images/Hero_Background.jpg') no-repeat center
    center;
  -webkit-background-size: fixed;
  -moz-background-size: fixed;
  -ms-background-size: fixed;
  -o-background-size: fixed;
  background-size: 100%;

  padding-top: 1vh;
  padding-left: 2rem;
  box-sizing: border-box;
} */

.bio-box .bio-link {
  display: block;
  margin: 2em auto 1em;
}

.desc {
  font-size: 20px;
  font-weight: bold;
  color: #d1c3b1;
  padding-left: 5rem;
}
.name {
  color: #d1c3b1;
  padding-left: 2rem;
}

.contact_github {
  height: 2rem;
  float: bottom;
  color: #d1c3b1;
}
.bio-box {
  font-size: 1rem;
  display: block;
  padding-left: 5rem;
  padding: 6rem 3% 1rem 5rem;
  width: 18%;
}
.bio-info {
  text-align: left;
}
.bio-box h3 {
  color: black;
  font-size: 1.5em;
  margin: 0px 0 1em;
  text-align: center;
}

.bio-box img {
  width: 100%;
  max-width: 144px;
  max-height: 144px;
  max-height: 200px;
  text-align: center;
  border-radius: 50%;
  margin-top: 4rem;
}

@media screen and (max-width: 800px) {
  html,
  body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    box-shadow: none;
  }

  .full-hero {
    padding-bottom: 1rem;
    float: left;
    text-align: center;
    width: 100%;
    height: 1000px;
  }

  .bio-info {
    background-color: #2c6c6c;
    box-shadow: rgba(0.7, 0, 0.8, 0.9) 0px 5px 45px;
    opacity: 0.859;
    border-radius: 25px;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
  }

  .desc {
    font-size: 20px;
    font-weight: bold;
    color: #d1c3b1;
    padding-left: 0rem;
  }
  .name {
    color: #d1c3b1;
    padding-left: 2rem;
  }

  .contact_github {
    height: 2rem;
    float: bottom;
    color: #d1c3b1;
  }

  .bio-box img {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 122px;
    max-height: 122px;
    object-fit: cover;
    height: auto;
  }

  .bio-box {
    float: none;
    width: auto;
    font-size: 1.7rem;
    padding: 2rem;
    padding-top: 2rem;
  }
}
